<template>
    <div class="box">
        <div class="box-header">
            <h4>原因标签</h4>
        </div>
        <div class="label-box b-card" v-for="(item,index) in items"
        :key="index">
            <h4>{{item.name}}</h4>
            <tagLabel :item.sync="item"/>
        </div>
    </div>
</template>

<script>
import PosService from '@admin/services/PosService'
import flatry from '@admin/utils/flatry'
import tagLabel from './components/_label'

export default {
  name: 'LabelFeedback',
  components: { tagLabel },
  data () {
    return {
      items: null
    }
  },
  async created () {
    const { data } = await flatry(PosService.createReasonLabel())
    if (data) {
      this.items = data.data
    }
  }
}
</script>
<style lang='scss' scoped>
.label-box{
    margin-bottom: 30px;
}
</style>
